import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import LoadingScreen from "./LoadingScreen";
import Error from "./Error";
import { getLiveData, runCurrentTask } from "../common/helpers/LiveData";
import Appendage from "../common/components/AppendageForm/Appendage";

const ApprovalScreen = ({ match, location }) => {
  const [approvalData, setApprovalData] = useState({
    nextTask: "",
    comment: "",
    decision: "",
  });
  const queryParams = queryString.parse(location.search);
  const dispatch = useDispatch();
  const appSlug = match.params.appSlug;
  const accountSlug = match.params.accountSlug;
  const history = useHistory();
  const { error, loading, task, workflowInstance, app, taskRunning } =
    useSelector(({ liveData }) => liveData);

  useEffect(() => {
    dispatch(
      getLiveData({
        appSlug,
        accountSlug,
        queryParam: `workflowInstanceId=${queryParams.workflowInstanceId}&taskId=${queryParams.taskId}&isApproval=true`,
        isApproval: true,
        relaunch: true,
      })
    );
  }, [
    appSlug,
    accountSlug,
    dispatch,
    queryParams.taskId,
    queryParams.workflowInstanceId,
  ]);

  const getTextAfterDelimiter = (str) => {
    if (!str) return "";

    const delimiter = ">>";

    const index = str.indexOf(delimiter);

    if (index === -1) {
      return str;
    }

    return str.substring(index + delimiter.length);
  };

  const onApprovalCommentChange = ({ name, value }) => {
    setApprovalData((prev) => ({ ...prev, [name]: value }));
  };
  const approveOrDeny = async (e) => {
    e.preventDefault();
    const prepareData = {
      taskId: queryParams.taskId,
      workflowInstanceId: queryParams.workflowInstanceId,
      data: approvalData,
    };
    let queryParam = `workflowInstanceId=${queryParams.workflowInstanceId}&taskId=${queryParams.taskId}`;
    dispatch(
      runCurrentTask({
        payload: prepareData,
        isApproval: queryParams.isApproval,
        queryParams: queryParam,
        history,
        accountSlug,
      })
    );
  };
  const prepareUserInputs = ({ variables = [], metadata = {} }) => {
    let mappedVariables = [],
      tableColumns = [],
      tableRows = [],
      tableAggregates = [],
      mappedValues = [],
      tableHead = "";

    variables.forEach((variable) => {
      if (
        variable?.info?.matching?.valueSourceType === "form" ||
        variable?.info?.matching?.valueSourceType === "user"
      ) {
        mappedVariables.push({
          id: variable?.id,
          name: variable?.info?.name,
          group: variable?.info?.group,
        });
      }
      if (variable?.info?.matching?.valueSourceType === "column") {
        tableColumns.push({
          id: variable?.id,
          type: variable?.info?.matching?.valueSourceType,
          name: getTextAfterDelimiter(variable?.info?.name),
        });
        tableHead = variable?.info?.name?.substring(
          0,
          variable?.info?.name.indexOf(">")
        );
      } else if (
        variable?.info?.matching?.valueSourceType === "aggregateCell"
      ) {
        tableAggregates.push({
          id: variable?.id,
          name: variable?.info?.name?.substring(
            variable?.info?.name.lastIndexOf(">") + 1,
            variable?.info?.name.length
          ),
        });
      }
    });

    Object.keys(metadata).forEach((metaValue) => {
      tableColumns.forEach((colObject) => {
        if (metaValue === colObject?.id) {
          colObject.values = metadata[colObject?.id];
          tableRows.push(colObject);
        }
      });
      tableAggregates.forEach((colObject) => {
        if (metaValue === colObject?.id) {
          colObject.value = metadata[colObject?.id];
          tableRows.push(colObject);
        }
      });
    });

    //console.log(metadata[variable.id]);
    mappedVariables.forEach((mVariable) => {
      if (Object.keys(metadata).length) {
        if (mVariable?.group.toLowerCase() !== "initiator") {
          metadata[mVariable?.id] &&
            mappedValues.push({
              [mVariable?.name]: metadata[mVariable?.id],
            });
        } else {
          if (mVariable?.name === "Initiator (name)" && !app.ispublic) {
            mappedValues.unshift({
              [mVariable?.name]: metadata[mVariable?.id],
            });
          }
        }
      }
    });

    return {
      mappedValues,
      tableProp: { tableRows, tableHead, tableAggregates },
    };
  };
  return (
    <div style={{ background: "#F4F6F8", paddingBottom: 15 }}>
      <LoadingScreen
        loading={loading || taskRunning}
        message={"Loading your application..."}
      />
      <Appendage
        error={error?.code}
        history={workflowInstance?.approvalHistory || []}
        decisions={task?.properties?.approvalActions || []}
        onChange={onApprovalCommentChange}
        onSubmit={approveOrDeny}
        taskName={task.name}
        app={app}
        loading={loading}
        taskRunning={taskRunning}
        userInputs={prepareUserInputs({
          metadata: workflowInstance?.metadata,
          variables: workflowInstance?.variables,
        })}
      />
      <Error status={error?.code} message={error?.message} />
    </div>
  );
};

export default ApprovalScreen;
