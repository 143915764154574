export const APPLICATION_SET_STATUS = "APPLICATION_SET_STATUS";
export const DISPLAY_TABLE_COLUMN = "DISPLAY_TABLE_COLUMN";
export const APPLICATION_SHOW_DIALOG = "APPLICATION_SHOW_DIALOG";

export const USERS_SAVE_USERGROUPS = "USERS_SAVE_USERGROUPS";

export const ADD_HIDE_INVOICETABLE_LABEL = "ADD_HIDE_INVOICETABLE_LABEL";
export const ADD_INVOICETABLE_BACK_COLOR = "ADD_INVOICETABLE_BACK_COLOR";
export const ADD_INVOICETABLE_BORDER_COLOR = "ADD_INVOICETABLE_BORDER_COLOR";
export const ADD_INVOICETABLE_ALIGN = "ADD_INVOICETABLE_ALIGN";
export const ADD_INVOICETABLE_TAX = "ADD_INVOICETABLE_TAX";
export const ADD_FILE_UPLOAD_LAYOUT = "ADD_FILE_UPLOAD_LAYOUT";
export const ADD_FILE_UPLOAD_COLOR = "ADD_FILE_UPLOAD_COLOR";
export const ADD_HIDE_LABEL = "ADD_HIDE_LABEL";
export const ADD_LABEL_NAME = "ADD_LABEL_NAME";
export const ADD_LABEL_NAME_TEXT_STYLE = "ADD_LABEL_NAME_TEXT_STYLE";
export const ADD_LABEL_NAME_TEXT_NAME = "ADD_LABEL_NAME_TEXT_NAME";
export const ADD_LABEL_NAME_TEXT_COLOR = "ADD_LABEL_NAME_TEXT_COLOR";
export const ADD_FILE_PREFERENCE_TEXT = "ADD_FILE_PREFERENCE_TEXT";
export const ADD_FILE_UPLOAD_FROM = "ADD_FILE_UPLOAD_FROM";
export const ADD_LABEL_NAME_TEXT_SIZE = "ADD_LABEL_NAME_TEXT_SIZE";
export const ADD_HIDE_TOOL_TIP = "ADD_HIDE_TOOL_TIP";
export const ADD_FILE_TOOLTIP_TEXT = "ADD_FILE_TOOLTIP_TEXT";
export const UPDATE_PROP = "UPDATE_PROP";
export const ACTIVATE_CANVAS = "ACTIVATE_CANVAS";
export const ADD_HIDE_INVOICE_LABEL = "ADD_HIDE_INVOICE_LABEL";
export const SAVE_CANVAS = "ACTIVATE_CANVAS";

export const USERS_LOAD_USERS = "USERS_LOAD_USERS";

export const UIEDITOR_LOAD_SCREEN_STYLE = "UIEDITOR_LOAD_SCREEN_STYLE";
export const UIEDITOR_REGISTER_POSITION = "UIEDITOR_REGISTER_POSITION";
export const UIEDITOR_ADD_ACTIVE_COMPONENT = "UIEDITOR_ADD_ACTIVE_COMPONENT";
export const UIEDITOR_UPD_ACTIVE_COMPONENT = "UIEDITOR_UPD_ACTIVE_COMPONENT";
export const UIEDITOR_RMV_ACTIVE_COMPONENT = "UIEDITOR_RMV_ACTIVE_COMPONENT";

export const UIEDITOR_SET_DROPZONE = "UIEDITOR_SET_DROPZONE";
export const UIEDITOR_LOAD_CUSTOM_ELEMENTS = "UIEDITOR_LOAD_CUSTOM_ELEMENTS";
export const UIEDITOR_SET_ZOOM_LEVEL = "UIEDITOR_SET_ZOOM_LEVEL";
export const UIEDITOR_SET_ACTIVE_COMPONENT = "UIEDITOR_SET_ACTIVE_COMPONENT";
export const UIEDITOR_SET_ACTIVE_ITEM = "UIEDITOR_SET_ACTIVE_ITEM";
export const UIEDITOR_SET_SEARCHED_ELEMENTS = "UIEDITOR_SET_SEARCHED_ELEMENTS";
export const UIEDITOR_SET_CANVAS_MODE = "UIEDITOR_SET_CANVAS_MODE";

export const UIEDITOR_COMPONENT_STYLE_CHANGE =
  "UIEDITOR_COMPONENT_STYLE_CHANGE";
export const UIEDITOR_TOGGLE_FULLSCREEN = "UIEDITOR_TOGGLE_FULLSCREEN";

export const SCREENS_SET_SCREENS_LIST = "SCREENS_SET_SCREENS_LIST";
export const SCREENS_SET_ACTIVE_SCREEN = "SCREENS_SET_ACTIVE_SCREEN";

export const WORKFLOWS_SET_WORKFLOWS_LIST = "WORKFLOWS_SET_WORKFLOWS_LIST";
export const WORKFLOWS_SET_EDIT_WORKFLOW = "WORKFLOWS_SET_EDIT_WORKFLOW";
export const WORKFLOWS_SHOW_TASKS = "WORKFLOWS_SHOW_TASKS";
export const WORKFLOWS_SET_ACTIVE_WORKFLOW = "WORKFLOWS_SET_ACTIVE_WORKFLOW";
export const WORKFLOWS_ACTIVE_ITEM = "WORKFLOWS_ACTIVE_ITEM";
export const WORKFLOWS_SET_SHOULD_REFETCH_WORKFLOW =
  "WORKFLOWS_SET_SHOULD_REFETCH_WORKFLOW";
export const WORKFLOWS_ADD_ITEM = "WORKFLOWS_ADD_ITEM";
export const WORKFLOWS_UPDATE_CANVAS = "WORKFLOWS_UPDATE_CANVAS";
export const WORKFLOWS_SET_TASKS = "WORKFLOWS_SET_TASKS";
export const WORKFLOWS_SET_BACKDROP = "WORKFLOWS_SET_BACKDROP";
export const WORKFLOWS_SET_VARIABLES = "WORKFLOWS_SET_VARIABLES";
export const WORKFLOWS_TOGGLE_FULLSCREEN = "WORKFLOWS_TOGGLE_FULLSCREEN";
export const WORKFLOWS_REMOTE_UPDATE_CANVAS_ELEMENTS =
  "WORKFLOWS_REMOTE_UPDATE_CANVAS_ELEMENTS";
export const WORKFLOWS_SET_INTEGRATIONS = "WORKFLOWS_SET_INTEGRATIONS";
export const WORKFLOWS_SET_DATASHEETS = "WORKFLOWS_SET_DATASHEETS";
export const WORKFLOW_IS_CRASHED = "WORKFLOW_IS_CRASHED";
export const WORKFLOWS_EXTERNAL_SET_ELEMENTS =
  "WORKFLOWS_EXTERNAL_SET_ELEMENTS";

export const WORKFLOWS_SET_HEAVY_TASKS = "WORKFLOWS_SET_HEAVY_TASKS";

export const EDITOR_SHOW_RIGHT_SIDEBAR = "EDITOR_SHOW_RIGHT_SIDEBAR";
export const EDITOR_SET_ERROR_LOADING_RIGHT_SIDEBAR =
  "EDITOR_SET_ERROR_LOADING_RIGHT_SIDEBAR";
export const EDITOR_SET_LOADING_RIGHT_SIDEBAR =
  "EDITOR_SET_LOADING_RIGHT_SIDEBAR";

export const UIEDITOR_SET_SCREENS_ITEMS = "UIEDITOR_SET_SCREENS_ITEMS";
export const UIEDITOR_SET_CANVAS_STRUCTURE = "UIEDITOR_SET_CANVAS_STRUCTURE";
export const UIEDITOR_SET_DRAG_START = "UIEDITOR_SET_DRAG_START";

// RUN APP
export const GET_LIVE_DATA_START = "GET_LIVE_DATA_START";
export const GET_LIVE_DATA_SUCCESS = "GET_LIVE_DATA_SUCCESS";
export const GET_LIVE_DATA_ERROR = "GET_LIVE_DATA_ERROR";

export const LIVE_RUN_FIRST_SCREEN = "LIVE_RUN_FIRST_SCREEN";
export const GET_LIVE_SCREEN_START = "GET_LIVE_SCREEN_START";
export const GET_LIVE_SCREEN_SUCCESS = "GET_LIVE_SCREEN_SUCCESS";
export const GET_LIVE_SCREEN_ERROR = "GET_LIVE_SCREEN_ERROR";

export const TASK_RUNNING = "TASK_RUNNING";

export const GET_SINGLE_SCREEN = "GET_SINGLE_SCREEN";
export const SET_LOADING_STATE = "SET_LOADING_STATE";
export const UPDATE_WORKFLOW_INSTANCE = "UPDATE_WORKFLOW_INSTANCE";

export const GET_SCREENS_FROM_STORAGE = "GET_SCREENS_FROM_STORAGE";

export const FILE_UPLOAD_START = "FILE_UPLOAD_START";
export const FILE_UPLOAD_SUCCESS = "FILE_UPLOAD_SUCCESS";

export const DELETE_UPLOADED_FILE = "DELETE_UPLOADED_FILE";

export const PLUG_BACKEND_UPDATE_ERROR_BANK = "PLUG_BACKEND_UPDATE_ERROR_BANK";
export const OPEN_APP_DETAILS_DIALOG = "OPEN_APP_DETAILS_DIALOG";

export const SET_PAGE_SEARCH_TEXT = "SET_PAGE_SEARCH_TEXT";

export const SET_TRIGGER_NEW_USER_VALUE = "SET_TRIGGER_NEW_USER_VALUE";
export const SET_TRIGGER_NEW_USERGROUP_VALUE =
  "SET_TRIGGER_NEW_USERGROUP_VALUE";
export const SET_TRIGGER_NEW_CATEGORY_VALUE = "SET_TRIGGER_NEW_CATEGORY_VALUE";
