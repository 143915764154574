import {
  FILE_UPLOAD_START,
  FILE_UPLOAD_SUCCESS,
  GET_LIVE_DATA_ERROR,
  GET_LIVE_DATA_START,
  GET_LIVE_DATA_SUCCESS,
  GET_LIVE_SCREEN_ERROR,
  GET_LIVE_SCREEN_START,
  GET_LIVE_SCREEN_SUCCESS,
  GET_SCREENS_FROM_STORAGE,
  LIVE_RUN_FIRST_SCREEN,
  SET_LOADING_STATE,
  TASK_RUNNING,
} from "../../../../store/actions/actionTypes";
import {
  rSaveActiveScreen,
  rUieSetCanvasStructure,
  rUpdateScreensList,
} from "../../../../store/actions/properties";
import { getAllActiveScreenItems } from "../../../EditorLayout/Pages/UIEditor/utils/screenUtilities";
import {
  getLiveDataService,
  getScreenService,
  runCurrentTaskService,
  relaunchAppService,
  uploadFileService,
  deleteUploadedFileService,
} from "./service";

export const getLiveData =
  ({ appSlug, accountSlug, queryParam, relaunch, isApproval }) =>
  async (dispatch) => {
    const { data, success } = await getLiveDataService({
      appSlug,
      accountSlug,
      queryParam,
      relaunch,
    });

    if (!success) {
      return dispatch({
        type: GET_LIVE_DATA_ERROR,
        payload: data?.data?._meta?.error,
      });
    }

    dispatch({
      type: GET_LIVE_DATA_START,
    });

    const payload = {
      screensInfo: data?.screens?.[0],
      task: data.task,
      taskVariables: data.taskVariables,
      workflowInstance: data.workflowInstance,
      app: data.app,
    };

    //  put screen in global state
    dispatch(rUpdateScreensList(data?.screens));
    dispatch(rSaveActiveScreen(data?.screens?.[0]));
    dispatch(rUieSetCanvasStructure(data?.screens?.[0]?.layout));
    dispatch(getAllActiveScreenItems(data?.screens?.[0]?.id, true));

    // //console.log(`GET_LIVE_DATA_START >>> ${JSON.stringify(payload)}`)
    localStorage.setItem("liveAppSlug", appSlug);
    localStorage.setItem("liveAccountSlug", accountSlug);
    return dispatch({
      type: GET_LIVE_DATA_SUCCESS,
      payload,
    });
  };

export const getLiveScreen = (slug, app, accountSlug) => async (dispatch) => {
  dispatch({ type: GET_LIVE_SCREEN_START });
  const { data, success } = await getScreenService(slug, app, accountSlug);
  if (!success) return dispatch({ type: GET_LIVE_SCREEN_ERROR, payload: data });
  dispatch({ type: GET_LIVE_SCREEN_SUCCESS, payload: data.data?.[0] || {} });
  // //console.log(`GET_LIVE_SCREEN_START >>> ${ JSON.stringify(data) }`);
};

export const relaunchApp = (arg) => async (dispatch) => {
  dispatch({
    type: SET_LOADING_STATE,
    payload: true,
  });
  const { data, success } = await relaunchAppService(arg);
  if (!success) {
    //console.log(data)
    return dispatch({
      type: GET_LIVE_DATA_ERROR,
      payload: data,
    });
  }
  const payload = {
    screensInfo: data?.screens[0]?.items || {},
    task: data.task,
    workflowInstance: data.workflowInstance,
    app: data.app,
  };
  dispatch({
    type: GET_LIVE_DATA_SUCCESS,
    payload,
  });
};

export const runCurrentTask =
  ({ payload, isApproval, queryParams, history, accountSlug }) =>
  async (dispatch) => {
    dispatch({
      type: TASK_RUNNING,
      payload: true,
    });
    const { success, data } = await runCurrentTaskService(
      payload,
      isApproval,
      queryParams
    );
    dispatch({
      type: TASK_RUNNING,
      payload: false,
    });

    if (!success) return;

    const { screens, app, task, workflowInstance } = data;
    if (screens.length > 0) {
      const screen = screens[0];
      history.push(
        `/run/${accountSlug}/${app?.slug}/${screen?.slug}?taskId=${task.id}&workflowInstanceId=${workflowInstance.id}&appId=${app.id}`
      );
      return;
    }

    history.push(`/run/completed`);
  };

export const uploadFile =
  (componentId, file, cb, isAssetRoute) => async (dispatch, getState) => {
    const filesUploaded = { ...getState().liveData.filesUploaded };
    const uploadingFile = { ...getState().liveData.uploadingFile };
    const isPublic = { ...getState().liveData.app.isPublic };

    dispatch({
      type: FILE_UPLOAD_START,
      payload: { ...uploadingFile, [componentId]: true },
    });

    const { success, data } = await dispatch(
      uploadFileService(file, isAssetRoute)
    );
    if (!success)
      return dispatch({
        type: FILE_UPLOAD_START,
        payload: { ...uploadingFile, [componentId]: false },
      });

    dispatch({
      type: FILE_UPLOAD_SUCCESS,
      payload: {
        uploadingFile: { ...uploadingFile, [componentId]: false },
        filesUploaded: { ...filesUploaded, [componentId]: data },
      },
    });

    //  formerly extracting 'url' of GCP bucket file, now constructing...
    //  url from id of file and download endpoint

    // cb(data.map((v) => v.url));

    //  first determine whether app is internal or external (add /public/)
    !!cb &&
      cb(
        data.map(
          (v) => v.url
          // `${process.env.REACT_APP_ENDPOINT}/download${
          //   isPublic ? "/public" : ""
          // }/${v.id}`
        )
      );
    if (success) return true;
  };

export const deleteUpdatedFile = (id, cid) => async (dispatch, getState) => {
  const state = { ...getState().liveData.filesUploaded };
  dispatch({
    type: FILE_UPLOAD_START,
    payload: true,
  });
  const { success } = await deleteUploadedFileService(id);
  if (!success)
    return dispatch({
      type: FILE_UPLOAD_START,
      payload: false,
    });

  delete state[cid];
  dispatch({
    type: FILE_UPLOAD_SUCCESS,
    payload: {
      filesUploaded: state,
    },
  });
};

export const getScreenFromStorage = (screenId) => (dispatch, getState) => {
  const screen = localStorage.getItem(screenId);
  if (!screen) return;
  dispatch({ type: GET_SCREENS_FROM_STORAGE, payload: JSON.parse(screen) });
};

export const setRunFirstScreen = (screenId) => (dispatch) => {
  dispatch({ type: LIVE_RUN_FIRST_SCREEN, payload: screenId });
};
